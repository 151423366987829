<template>
    <div class="w-[full] h-[85vh] mt-[8vh] flex justify-center items-center">
        <div class="forgotPass" :class="{ expandWindow: requiredFields.password }">
            <h1>Change Password</h1>
            <div class="input_group">
                <label for="password_forgot">{{ $t("New Password") }}</label>
                <input
                    :type="showPassword ? 'text' : 'password'"
                    @input="checkValidity"
                    v-model="password"
                    name="password_forgot"
                    id="password_forgot"
                    :placeholder="$t('Fill in your new password')"
                />
                <button class="showpassword" @click.prevent="showPassword = !showPassword">
                    <img loading="lazy" decoding="async" class="eye-icon" :src="require(`../assets/${showPassword ? 'icon_eye_closed.svg' : 'icon_eye.svg'}`)" width="25" height="25" alt="" />
                </button>
                <span v-if="requiredFields.password" class="err_msg">{{ requiredFields.password }}</span>
            </div>
            <div class="input_group">
                <label for="password_forgot">{{ $t("Confirm Password") }}</label>
                <input
                    :type="showConfirmPassword ? 'text' : 'password'"
                    @input="checkValidity"
                    name="password_forgot"
                    id="password_forgot"
                    :placeholder="$t('Confirm Password')"
                    v-model="this.confirmPassword"
                />
                <button class="showpassword" @click.prevent="showConfirmPassword = !showConfirmPassword">
                    <img loading="lazy" decoding="async" class="eye-icon" :src="require(`../assets/${showConfirmPassword ? 'icon_eye_closed.svg' : 'icon_eye.svg'}`)" width="25" height="25" alt="" />
                </button>
                <span v-if="requiredFields.confirmPassword" class="err_msg">{{ requiredFields.confirmPassword }}</span>
            </div>
            <div class="btnWrapper">
                <button v-if="!this.loading" @click="changePassword()">Change</button>
                <button v-else class="loading-btn flex justify-center">
                    <LoaderComponent />
                </button>
            </div>
            <div>
                <span v-if="reponseMessage">{{ reponseMessage }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "@/components/LoaderComponent.vue";
import axios from "axios";

export default {
    name: "ChangePassword",
    components: {
        LoaderComponent,
    },
    data() {
        return {
            password: "",
            confirmPassword: "",
            requiredFields: {
                password: "",
                confirmPassword: "",
            },
            showPassword: false,
            showConfirmPassword: false,
            token: "",
            reponseMessage: "",
            loading: false,
        };
    },
    methods: {
        changePassword() {
            if (this.checkValidity()) {
                this.loading = true;
                axios
                    .post(
                        `https://server.go-platform.com/user/changeUserPassword`,
                        {
                            password: this.password,
                            confirmPassword: this.confirmPassword,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${this.token}`,
                            },
                            withCredentials: true,
                        },
                    )
                    .then((response) => {
                        this.loading = false;
                        this.reponseMessage = response.data.message;
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loading = false;
                        this.reponseMessage = error.response.data.message;
                    });
            }
        },
        checkValidity() {
            let valid = true;
            this.requiredFields = {
                password: "",
                confirmPassword: "",
            };
            const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/;
            if (!passwordRegex.test(this.password)) {
                this.requiredFields["password"] = "Password must contain at least one uppercase letter, one special character, and be at least 8 characters long.";
                valid = false;
            }
            if (this.password !== this.confirmPassword) {
                this.requiredFields["confirmPassword"] = "Password does not match.";
                valid = false;
            }

            return valid;
        },
    },
    mounted() {
        this.token = this.$route.query.token;
    },
};
</script>

<style scoped lang="scss">
.forgotPass {
    background-color: #fff;
    border-radius: 10px;
    width: 40%;
    height: 60%;
    padding: 2% 6%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    // box-shadow: 0px 0px 10px 2px rgba(195, 195, 195, 0.8);
    box-shadow:
        20px 20px 60px #bebebe,
        -20px -20px 60px #ffffff;
    transition: all 0.3s ease;

    h1 {
        color: #0f172a;
        font-family: Roboto;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 42px;
        /* 175% */
        letter-spacing: -0.48px;
        width: 90%;
        margin: 3%;
    }
}

.input_group {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    padding: 0;
    margin-top: 20px;
    width: 70%;
    margin: 20px 15%;
    position: relative;

    label {
        display: flex;
        font-weight: 500;
        font-size: 18px;
        line-height: 12px;
        align-items: center;
        margin-bottom: 5px;
    }

    input {
        border: 1px solid #e5e5ef;
        box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
        border-radius: 4px;
        width: 100%;
        height: 2.5rem;
        padding: 0 0.5rem;
        font-size: 16px;
    }

    div {
        display: flex;
        align-items: center;
        border: 1px solid #e5e5ef;
        height: 2.5rem;
        border-radius: 4px;

        button {
            width: auto;
            height: fit-content;
            background: none;
        }

        input {
            // border: none;
        }
    }
}

.btnWrapper {
    button {
        cursor: pointer;
        transition: 0.2s ease-in;
        background-color: #2196f3;
        color: white;
        width: 70%;
        padding: 12px;
        justify-content: center;
        border-radius: 5px;
        font-size: 20px;
        font-family: "Roboto";
        height: 50px;
        font-weight: 600;

        &:hover {
            opacity: 0.85;
        }
    }

    .loading-btn {
        opacity: 0.85;
        align-items: center;
        margin: 0 15%;
    }
}

.expandWindow {
    height: 70%;
}

.err_msg {
    color: #ff6969;
    font-size: 14px;
    font-weight: 300;
    position: absolute;
    top: 100%;
    text-align: left;
}

.showpassword {
    position: absolute;
    top: 70%;
    right: 5%;
    transform: translateY(-50%);
}
</style>
