<template>
    <div v-if="isLoading" class="loader">
        <LoadingComponent />
    </div>
    <section v-else :style="{ overflowY: isShown ? 'hidden' : 'visible' }" class="blogs-container relative">
        <table>
            <thead>
                <tr>
                    <th>Title</th>
                    <th>Category</th>
                    <th>Required skills</th>
                    <!-- <th>Date</th> -->
                    <th class="actions">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="JobPosition in jobPositions" :key="JobPosition._id">
                    <td class="title-case w-1.5/5">{{ JobPosition.title }}</td>
                    <td class="w-1/5">{{ JobPosition.category }}</td>
                    <td class="w-1/5">{{ JobPosition.requiredSkills.length }}</td>
                    <!-- <td class="w-0.5/5">{{ getDate(JobPosition.createdAt) }}</td> -->
                    <td class="actions w-1/5">
                        <font-awesome-icon @click="selectJobPosition(JobPosition._id)" class="icons w-4 info mr-1 h-4 p-2 text-white" :icon="['fas', 'circle-info']" />
                        <font-awesome-icon
                            class="icons w-4 mr-1 h-4 p-2 update text-white"
                            :icon="['far', 'pen-to-square']"
                            @click="
                                () => {
                                    this.editing = true;
                                    selectJobPosition(JobPosition._id);
                                }
                            "
                        />
                        <font-awesome-icon @click="deleteJobPosition(JobPosition._id)" class="icons w-4 h-4 bg-red-500 p-2 text-white" :icon="['fas', 'trash']" />
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="w-[25%] mx-auto flex justify-between text-[rgb(121,121,121)]">
            <button @click="previousPage"><font-awesome-icon :icon="['fas', 'chevron-left']" id="slide1" /></button>
            <p class="w-[80%] flex items-center justify-center">{{ this.page }} / {{ this.totalPages }}</p>
            <button @click="nextPage"><font-awesome-icon :icon="['fas', 'chevron-right']" id="slide2" /></button>
        </div>

        <div v-show="JobPositiondetail" class="fixed top-0 right-0 bottom-0 left-0 z-50 flex justify-center items-center">
            <div class="fixed top-0 right-0 bottom-0 left-0 -z-10 bg-[rgba(0,0,0,45%)]" @click="closeDetail"></div>
            <div class="w-10/12 h-[80vh] flex flex-col justify-start items-start bg-white border-2 border-red-200 text-slate-700 text-base font-normal rounded-md p-6 overflow-y-scroll">
                <div class="mb-6 w-full flex flex-row justify-end items-center gap-2">
                    <font-awesome-icon class="icons w-4 mr-1 h-4 p-2 update text-white" :icon="['fas', 'ban']" />
                    <font-awesome-icon @click="deleteJobPosition(selectedJobPosition._id)" class="icons w-4 h-4 bg-red-500 p-2 text-white" :icon="['fas', 'trash']" />
                    <span v-if="!editing" @click="() => (editing = !editing)" class="icons update rounded-md p-2">Edit</span>
                    <div v-else>
                        <span
                            @click="() => (editing = !editing)"
                            v-if="JSON.stringify(editingJobPosition) === JSON.stringify(selectedJobPosition)"
                            class="bg-[#d5efff] rounded-md px-3 py-2 mx-2 my-3 text-[#05152e]"
                            >Cancel</span
                        >
                        <span @click="updateJobPositions" class="bg-green-500 rounded-md px-3 py-2 mx-2 my-3 text-white" v-else>Save</span>
                    </div>
                </div>
                <div class="w-full p-5 flex flex-col justify-between items-start gap-6">
                    <div class="flex gap-8">
                        <h2 class="text-[16px] font-semibold">Job title:</h2>
                        <h1 v-if="!editing" class="text-[20px] font-bold">
                            {{ selectedJobPosition.title }}
                        </h1>
                        <input class="text-[20px] font-bold border rounded-[10px] p-2" v-else v-model="editingJobPosition.title" type="text" />
                    </div>
                    <hr class="h-[8px] bg-[#F5F8FF] w-full mb-[2%]" />

                    <div class="flex gap-8">
                        <h2 class="text-[16px] font-semibold">Job Category:</h2>
                        <h1 v-if="!editing" class="text-[20px] font-bold">
                            {{ selectedJobPosition.category }}
                        </h1>
                        <select v-else name="category" id="" v-model="editingJobPosition.category">
                            <option v-for="(category, index) in categories" :key="index" :value="category">{{ category }}</option>
                        </select>
                    </div>
                    <hr class="h-[8px] bg-[#F5F8FF] w-full mb-[2%]" />

                    <div class="flex flex-col gap-8">
                        <h2 class="text-[16px] font-semibold">Required Skills:</h2>
                        <div class="text-[14px] font-bold">
                            <div v-if="editing" class="flex items-center my-4">
                                <input type="text" v-model="requiredSkill" placeholder="Enter a new required skill" class="text-[14px] border rounded-[5px] w-[300px] h-[40px]" />
                                <button @click="addRequiredSkill" class="bg-[#d5efff] rounded-md px-3 py-2 mx-2 my-3 text-[#05152e]" style="width: 150px">Add</button>
                            </div>
                            <div class="flex flex-wrap gap-4">
                                <span class="bg-[#d5efff] relative rounded-md px-3 py-2 text-[#05152e]" v-for="(required, index) in editingJobPosition?.requiredSkills" :key="index">
                                    {{ required }}
                                    <div
                                        class="absolute top-[-5px] right-[-5px] w-[12px] h-[12px] flex items-center justify-center text-red-500 rounded-full bg-white p-2 shadow-md"
                                        @click="removeRequired(required)"
                                        v-if="editing"
                                    >
                                        <font-awesome-icon :icon="['fas', 'xmark']" />
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from "axios";
import getDateAndTime from "@/helpers/getDateandTime";
// import getImgUrl from "@/helpers/getImage";
import LoadingComponent from "../components/LoadingComponent.vue";

export default {
    name: "JobPositionsAdmin",
    components: {
        LoadingComponent,
    },
    data() {
        return {
            isLoading: true,
            JobPositiondetail: false,
            selectedJobPosition: {},
            isShown: false,
            jobPositions: [],
            editing: false,
            requiredSkill: "",
            editingJobPosition: this.selectedJobPosition,
            categories: [
                "Others",
                "Information Technology",
                "Software Development",
                // "Healthcare and Medical Services",
                "Sales and Marketing",
                "Education and Training",
                "Finance and Accounting",
                "Engineering",
                "Customer Service and Support",
                "Human Resources",
                "Manufacturing and Production",
                "Management and Leadership",
            ],
            page: 1,
            totalPages: 0,
        };
    },
    computed: {
        keys() {
            return Object.keys(this.selectedJobPosition);
        },
    },
    methods: {
        getDate(str) {
            return getDateAndTime(str);
        },
        VerifyJobPosition() {
            console.log("JobPosition verified");
            this.selectedJobPosition.isVerified = true;
        },
        getImage(imgFileId) {
            if (imgFileId) {
                var image = `data:image/png;base64,${imgFileId}`;
                console.log({ image });
                return image;
            }
            return require("../assets/Images/candidate-image.png");
        },
        selectJobPosition(id) {
            this.selectedJobPosition = this.jobPositions.find((JobPosition) => JobPosition._id === id);
            this.editingJobPosition = { ...this.selectedJobPosition };
            console.log({ EDITINGOBJ: this.editingJobPosition, ORIGINAL: this.selectedJobPosition });
            this.JobPositiondetail = true;
        },
        closeDetail() {
            this.selectedJobPosition = {};
            this.JobPositiondetail = false;
            this.editing = false;
        },
        removeRequired(required) {
            console.log({ required });
            let requiredSkills = this.editingJobPosition.requiredSkills;
            let newTable = requiredSkills.filter((skill) => skill !== required);
            this.editingJobPosition.requiredSkills = newTable;
        },
        addRequiredSkill() {
            this.editingJobPosition.requiredSkills = [...this.editingJobPosition.requiredSkills, this.requiredSkill];
            this.requiredSkill = "";
            console.log({ EDITIGN: this.editingJobPosition });
        },
        deleteJobPosition(id) {
            this.JobPositiondetail = false;
            let config = {
                method: "DELETE",
                maxBodyLength: Infinity,
                url: `https://server.go-platform.com/jobPositions/${id}`,
                headers: {
                    "Content-Type": "application/json",
                },
                withCredentials: true,
            };
            axios
                .request(config)
                .then((response) => {
                    if (response.data.deleted) {
                        const jobPositions = this.jobPositions.filter((JobPosition) => JobPosition._id != id);
                        this.jobPositions = jobPositions;
                        // console.log('JobPosition was deleted')
                    } else console.log("JobPosition was not deleted");
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        async fetchJobPositions() {
            this.isLoading = true;
            let config = {
                method: "get",
                maxBodyLength: Infinity,
                url: `https://server.go-platform.com/jobPositions/getAllJobPositionsPerPage?page=${this.page}`,
                headers: {
                    query: {
                        page: 3,
                    },
                },
                withCredentials: true,
            };

            axios
                .request(config)
                .then((response) => {
                    console.log({ response });
                    this.jobPositions = response.data.data;
                    this.totalPages = response.data.totalPages;
                    this.isLoading = false;
                    // this.jobPositions = response.data.data;
                    // this.searchedJob = response.data.data;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        async previousPage() {
            if (this.page > 1) {
                this.page = this.page - 1;
                await this.fetchJobPositions();
                window.scrollTo({ top: 0, behavior: "smooth" });
            }
        },
        async nextPage() {
            this.page = this.page + 1;
            await this.fetchJobPositions();
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
        updateJobPositions() {
            let data = JSON.stringify({
                jobposition: this.editingJobPosition,
            });
            console.log({ data });
            let config = {
                method: "PUT",
                maxBodyLength: Infinity,
                url: `https://server.go-platform.com/jobPositions/updateOne`,
                headers: {},
                data: this.editingJobPosition,
                withCredentials: true,
            };

            axios
                .request(config)
                .then((response) => {
                    console.log({ response });
                    this.editing = false;
                    this.selectedJobPosition = this.editingJobPosition;
                    // this.isLoading = false;
                    // this.jobPositions = response.data.data;
                    // this.searchedJob = response.data.data;
                })
                .catch((error) => {
                    console.log(error);
                    // this.isLoading = false;
                });
        },
    },
    mounted() {
        this.fetchJobPositions();
    },
    watch: {
        editingJobPosition: {
            handler(newVal) {
                // if (newVal.length > 0) {
                console.log({ newVal });
                // }
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.blogs-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
}

.btn-section {
    width: 100%;
    display: flex;
    align-items: flex-end;
}

table {
    width: 100%;
    text-align: left;
    border: 1px solid #ddd;
}

thead {
    background: #2196f3;
    color: #fff;
}

tbody {
    background: #fff;
}

.loading-tr {
    background: #2196f3;
    height: 40px;
}

.loading-body {
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

th {
    padding: 8px 10px;
    text-align: start;
    border-bottom: 1px solid #ddd;
    font-weight: 600;
    font-size: 16px;
}

.actions {
    text-align: center;
}

td {
    padding: 5px 10px;
    border-bottom: 1px solid #ddd;
    font-size: 15px;
}

.icons {
    cursor: pointer;

    &:hover {
        opacity: 0.85;
    }
}

.update {
    background: #2196f3;
}

.info {
    background: #5bb98b;
}

button {
    width: 100%;
    height: 40px;
    padding: 0.5rem;
    border-radius: 10px;
    border: none;
    background: #2196f3;
    font-weight: 500;
    color: #fff;
    box-shadow:
        0 2px 4px rgba(0, 0, 0, 0.1),
        0 4px 8px rgba(0, 0, 0, 0.1);

    &:hover {
        opacity: 0.85;
    }
}

a {
    width: 15%;
}

.title-case {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 300px;
}

.loader {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
