<template>
    <contactUs />
</template>

<script>
import contactUs from "@/views/contactUs.vue";
export default {
    name: "ContactUsView",
    components: {
        contactUs,
    },
};
</script>
