<template>
    <div class="h-[100vh] items-center justify-center flex flex-col w-full">
        <DropdownComponent align="left" width="15rem">
            <button>Open Menu</button>
            <template #items>
                <DropdownItemGroup>
                    <DropdownItem> Edit </DropdownItem>
                    <DropdownItem>Duplicate</DropdownItem>
                </DropdownItemGroup>
                <DropdownItemGroup label="Actions">
                    <DropdownItem description="Don't worry, you can undo.">Archive</DropdownItem>
                    <DropdownItem> Duplicate </DropdownItem>
                    <DropdownItem>Move</DropdownItem>
                    <DropdownItem>Share</DropdownItem>
                </DropdownItemGroup>
                <DropdownItemGroup>
                    <DropdownItem
                        ><label class="checkbox-label">
                            <input type="checkbox" class="checkbox-input" />
                            <span class="checkbox-text">Hard Skills</span>
                        </label></DropdownItem
                    >
                </DropdownItemGroup>
            </template>
        </DropdownComponent>
    </div>
</template>

<script>
import DropdownComponent from "@/components/ReusableComponents/DropdownComponent.vue";
import DropdownItemGroup from "@/components/ReusableComponents/DropdownItemGroup.vue";
import DropdownItem from "@/components/ReusableComponents/DropdownItem.vue";

export default {
    name: "DropdownView",
    components: {
        DropdownComponent,
        DropdownItemGroup,
        DropdownItem,
    },
};
</script>
