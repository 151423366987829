<template>
    <div v-if="!openChat">
        <select v-model="selectedContact">
            <option v-for="contact in contacts" :key="contact.id" :value="contact.id">
                {{ contact.name }}
            </option>
        </select>
        <button @click="openChatWithSelectedContact">{{ $t("Send Message") }}</button>
    </div>
    <!--<button v-if="!openChat" @click="openChat=!openChat">Send Message</button>-->
    <div class="chat-container" v-if="openChat">
        <!--<button @click="openChat=!openChat">close</button>-->
        <button @click="closeChat">{{ "Close" }}</button>
        <div class="list-container">
            <div v-for="message in messages" :key="message.id" :class="{ right: message.sender == currentUser }" class="message-box">
                <b> {{ message.sender }}: </b>
                {{ message.message }}
            </div>
        </div>
        <div class="text-input-container">
            <textarea v-model="text" class="text-message" @keyup.enter="sendMessage"></textarea>
        </div>
    </div>
</template>

<script>
//import io from "socket.io-client";
import axios from "axios";
export default {
    data() {
        return {
            currentUser: "",
            text: "",
            messages: [],
            right: false,
            openChat: false,
            contacts: [], // Array of contacts to choose from
            selectedContact: null,
            socketInstance: null,
        };
    },
    methods: {
        async retrieveContacts() {
            try {
                const response = await axios.get("https://server.go-platform.com/data/users", {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                });
                const users = response.data;
                const contacts = users.map((user) => {
                    return {
                        id: user._id,
                        name: `${user.firstName} ${user.lastName}`,
                    };
                });
                return contacts;
            } catch (error) {
                console.log(error);
            }
        },
        openChatWithSelectedContact() {
            if (this.selectedContact) {
                this.currentUser = this.contacts.find((contact) => contact.id === this.selectedContact).name;
                this.openChat = true;
            }
            this.retrieveDiscussions();
        },
        closeChat() {
            this.openChat = false;
        },

        sendMessage() {
            this.addMessage();
            this.text = "";
        },
        addMessage() {
            const message = {
                id: new Date().getTime(),
                message: this.text,
                sender: this.currentUser,
            };

            this.messages = this.messages.concat(message);
            this.socketInstance.emit("message", message);
            try {
                axios.post("https://server.go-platform.com/post/chat", message);
            } catch (err) {
                console.log(err);
            }
        },
        async retrieveDiscussions() {
            try {
                const response = await axios.get("https://server.go-platform.com/data/chat", {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                });
                this.messages = response.data;
            } catch (error) {
                console.error(error);
            }
        },
    },
    name: "ChatRoom",
};
</script>

<style scoped>
.list-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-grow: 1;
    padding: 2%;
    background: #fff;
    border: 1px solid #000;
    gap: 0.25rem;
}

.chat-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2%;
}

.name-container {
    display: flex;
    flex-direction: column;
    width: 200px;
}

.user-name {
    height: 30px;
    font-size: 20px;
    padding: 5px;
    margin-bottom: 5px;
    text-align: center;
    font-weight: bold;
}

.join-button {
    height: 30px;
    font-size: 20px;
}

.text-input-container {
    height: 250px;
    width: 100%;
    flex-grow: 0;
}

.text-message {
    width: 100%;
    height: 70px;
    padding: 10px;
    box-sizing: border-box;
}

.message-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

.right {
    align-items: flex-end;
}
</style>
<!--
	<template>
	<div class="chat">
	<div class="chat-header">
		<h2>{{ room }}</h2>
	</div>
	<div class="chat-body">
		<ChatMessage v-for="message in messages" :key="message.id" :message="message" />
	</div>
	<div class="chat-footer">
		<div class="user-list">
		<UserList :users="users" />
		</div>
		<div class="message-box">
		<form @submit.prevent="sendMessage">
			<input type="text" v-model="messageText" placeholder="Type your message..." />
			<button type="submit">Send</button>
		</form>
		</div>
	</div>
	</div>
</template>

<script>
import ChatMessage from '../components/ChatMessage.vue';
import UserList from '../components/UserList.vue';
import { mapGetters } from 'vuex'

export default {
	name: 'ChatRoom',
	data () {
	return {
		messageText: '',
		messages: [],
		users: [],
	}
	},
	components: {
		ChatMessage,
		UserList,
	},
  
	computed: {
	...mapGetters({
		socket: 'socket/getSocket'
	})
	},
	methods: {
	login () {
		if (this.username) {
		this.socket.emit('userConnected', this.username)
		}
	},
	sendMessage () {
		if (this.message) {
			this.socket.emit('newMessage', {
			username: this.username,
			message: this.message
		})
		this.message = ''
		}
	}
	},
	created () {
	this.socket.on('messageReceived', data => {
		this.messages.push(data)
	})
	}
}
</script>

<style>
	ul {
	list-style-type: none;
	padding: 0;
	}
</style>
-->
