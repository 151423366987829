<template>
    <div class="pop flex items-center justify-center min-h-screen">
        <div class="bg-white rounded-md w-full">
            <div v-if="pass">
                <!--div style="font-weight: bold">Please enter your Email to pass the test</!--div>
                <div class="input_group">
                    <input type="text" id="log_email" name="log_email" v-model="email" required />
                    <label for="log_email" class="required floating_label" :class="{ active: email }"><font-awesome-icon :icon="['far', 'envelope']" class="icon" /> {{ $t("Email") }}</label>
                    <span v-if="emailError" class="err_msg">{{ emailError }}</span>
                </div>
                <div-- style="margin-top: 50px; text-align: right">
                    <button style="padding: 10px 20px; cursor: pointer; background-color: #00aef0; color: white; font-weight: 700; border-radius: 8px" @click="check" :disabled="isButtonDisabled">
                        Next
                    </button>
                </div-->

                <div class="flex">
                    <!-- Left Section (Image) -->
                    <div class="w-1/2 relative">
                        <img src="../assets/email3.jpg" class="h-full w-full object-cover" alt="Background Image" />
                        <br />
                    </div>

                    <!-- Right Section (Form) -->
                    <br />
                    <br />
                    <div class="w-1/2 flex flex-col justify-center items-start px-16 mt-15" style="margin-top: 10px">
                        <br />
                        <!-- Heading and Subtitle -->
                        <h1 class="text-2xl mt-10 font-bold tracking-tightest text-left">Start Your Assessment Now!</h1>
                        <p class="text-gray-300 mb-4 text-left">Get your skills Noticed.</p>

                        <!-- Form Input (Email Address) -->
                        <div class="w-full mt-8 mb-4">
                            <label for="log_email" class="block text-sm text-gray-400 text-left required floating_label" :class="{ active: email }"> Email address</label>

                            <input
                                id="log_email"
                                name="log_email"
                                v-model="email"
                                required
                                type="text"
                                class="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Enter your email"
                            />
                            <span v-if="emailError" class="err_msg">{{ emailError }}</span>
                        </div>

                        <!-- Terms of Use and Privacy Policy -->
                        <p class="text-xs text-gray-500 font-inter leading-[44px] tracking-tightest text-left" style="margin-bottom: -8px">By creating an account, you agree to our</p>
                        <p class="text-xs text-gray-500 font-inter leading-[44px] tracking-tightest text-left" style="margin-top: -15px">
                            <a href="#" class="text-black underline">Terms of use</a> and <a href="#" class="text-black underline">Privacy Policy</a>.
                        </p>
                        <!------------------------------------------------------------------------------------------>
                        <div class="rounded-md px-4 py-2 flex items-center mt-4 justify-between shadow-sm border border-gray-800 w-200 mb-4">
                            <!-- Left Side: Checkbox & Text -->
                            <div class="flex items-center gap-2">
                                <!-- Checkbox Icon (This can be customized to fit the reCAPTCHA look) -->
                                <div
                                    @click="isChecked = !isChecked"
                                    :class="isChecked ? 'bg-green-500' : 'bg-white'"
                                    class="w-4 h-4 border border-gray-600 text-white rounded-sm flex items-center justify-center"
                                >
                                    <!-- A checkmark icon or other svg can be used here -->
                                    <svg v-if="isChecked" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-4 h-4 text-white">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                    </svg>
                                </div>
                                <span class="text-gray-900 text-lg font-inter text-left text-xs mr-10">I'm not a robot</span>
                            </div>

                            <!-- Right Side: reCAPTCHA Badge -->
                            <div class="text-right">
                                <!-- reCAPTCHA badge image (adjust with custom CSS or Tailwind) -->
                                <img src="../assets/recaptcha.png" alt="reCAPTCHA Badge" class="h-8 w-8" />
                            </div>
                        </div>

                        <button
                            @click="check"
                            :disabled="isButtonDisabled"
                            :class="
                                isButtonDisabled
                                    ? 'w-100 text-white py-2 rounded-md p-3 bg-gray-400 focus:outline-none cursor-not-allowed '
                                    : 'w-100 text-white py-2 rounded-md p-3  bg-[#3db5e6] focus:outline cursor-allowed'
                            "
                        >
                            START ASSESSMENT
                        </button>

                        <!-- Footer (Powered by) -->
                        <div class="mt-8 text-center w-full">
                            <span class="text-xs text-gray-400">Powered by</span>
                            <br />
                            <img src="../assets/Images/go_logo.svg" class="h-6 inline-block" alt="Go Platform Logo" />
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="">
                <div style="margin-bottom: 50px; font-weight: bold">Congratulation Now you can pass the test</div>
                <div class="flex items-center justify-center" style="text-align: center"><img :src="require(`../assets/stati3.png`)" style="width: 30%" /></div>
                <div style="margin-top: 50px; text-align: right">
                    <a :href="link" style="padding: 10px 20px; cursor: pointer; background-color: #00aef0; color: white; font-weight: 700; border-radius: 8px" @click="check">Pass assessment</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "InviteC",
    data() {
        return {
            isChecked: false, // Checkbox state (default is unchecked)
            emailError: "",
            pass: true,
            //isButtonDisabled: false,
            token: null,
            email: "",
            link: "",
        };
    },
    computed: {
        // Disable button if email is empty or checkbox is not checked
        isButtonDisabled() {
            return !(this.email && this.isChecked);
        },
    },
    mounted() {
        /*
        this.$recaptcha.init({
      siteKey: 'your-site-key',
    })*/
    },
    methods: {
        check() {
            let isValid = true;
            const emailRegex = /^\S+@\S+\.\S+$/; // Regular expression for basic email format
            this.isButtonDisabled = true;
            if (!emailRegex.test(this.email)) {
                this.emailError = "Please enter a valid email address.";
                isValid = false; // Update the formValid flag

                this.isButtonDisabled = false;
            } else {
                this.emailError = "";
                this.pass = true;
                isValid = true;
                this.isButtonDisabled = true;
            }
            this.token = this.$route.query.token;
            if (isValid) {
                this.isButtonDisabled = true;

                axios
                    .post(
                        "https://server.go-platform.com/inviteCandidate/generate2/?token=" + this.token,
                        { email: this.email },
                        {
                            withCredentials: true,
                        },
                    )
                    .then((response) => {
                        console.log(response);

                        if (response.data.message == "Invalid token!") {
                            alert("link has expired");
                        } else {
                            this.link = response.data.message;
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.pass = true;
                        this.isButtonDisabled = false;
                    });
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.pop {
    height: 100vh;
    width: 100%;
    display: flex;
    position: fixed;
    background: #f8f8fa;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
}

.input_group {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    padding: 0;
    margin-top: 20px;
    position: relative;

    label {
        display: flex;
        font-weight: 500;
        font-size: 18px;
        line-height: 12px;
        align-items: center;
        margin-bottom: 5px;
    }

    .required {
        &::after {
            content: "*";
            color: #ff6969;
        }
    }

    .err_msg {
        color: #ff6969;
        font-size: 14px;
        font-weight: 300;
        position: absolute;
        top: 100%;
    }

    .floating_label {
        position: absolute;
        top: 50%;
        left: 0;
        margin-left: 5px;
        padding: 0 1% 0 0;
        transform: translateY(-50%);
        pointer-events: none;
        background: #fff;
        transition: all 0.3s ease;

        .icon {
            margin-right: 10px;
            color: #2196f3;
        }
    }

    .floating_label.active {
        box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
        border-radius: 50px;
        background: #fff;

        transform: translateY(-170%);
    }

    input:focus + .floating_label {
        box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
        border-radius: 50px;
        background: #fff;
        transform: translateY(-170%);
    }

    input {
        border: 1px solid #e5e5ef;
        box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
        border-radius: 4px;
        width: 100%;
        height: 2.5rem;
        padding: 0 0.5rem;
        font-size: 16px;
    }

    div {
        display: flex;
        align-items: center;
        border: 1px solid #e5e5ef;
        height: 2.5rem;
        border-radius: 4px;

        button {
            width: auto;
            height: fit-content;
            background: none;
        }

        input {
        }
    }

    @media (max-width: 767.98px) {
        /* Your CSS styles for smartphones go here */
        margin-top: 0;
    }
}

.logo {
    font-weight: 900;
    font-size: 40px;
    line-height: 18px;
    color: #2196f3;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin: 1rem 0 3rem;
    gap: 0.5rem;
    position: absolute;
    top: 1%;
    left: 0;
    padding: 15px;

    @media (min-width: 768px) and (max-width: 991.98px) {
    }

    @media (max-width: 767.98px) {
        /* Your CSS styles for smartphones go here */
        font-size: 32px;
    }
}
</style>
